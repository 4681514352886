import React, { Component } from 'react';
import * as THREE from 'three';

class CanvasThree extends Component {
  componentDidMount() {
    window.addEventListener('scroll', this.scrollCamera);
    window.addEventListener('resize', this.handleResize);
    const width = this.mount.clientWidth;
    const height = this.mount.clientHeight;

    // Add Scene
    this.scene = new THREE.Scene();

    // Add Camera
    this.camera = new THREE.PerspectiveCamera(75, width / height, 0.1, 1000);
    this.camera.position.z = 500;

    // Add Renderer
    this.renderer = new THREE.WebGLRenderer({ alpha: true });
    this.renderer.setClearColor('#000000');
    this.renderer.setSize(width, height);
    this.mount.appendChild(this.renderer.domElement);

    // Add Pink and Purple Ambient Light
    this.scene.add(new THREE.AmbientLight(0xFF99FF, 1.5));

    // Add Stars
    const stars = new THREE.Object3D();
    const starMaterial = new THREE.MeshBasicMaterial({ color: 0xFFFFFF });

    for (let i = 0; i < 500; i++) {
      const starGeometry = new THREE.SphereGeometry(2, 8, 8);
      const star = new THREE.Mesh(starGeometry, starMaterial);
      star.position.set(
        Math.random() * 5000 - 2500,
        Math.random() * 5000 - 2500,
        Math.random() * 5000 - 2500
      );
      stars.add(star);
    }

    this.scene.add(stars);

    // Add Planets
    this.addPlanets();

    // Add Blobs
    this.addBlobs();

    this.start();
  }

  addPlanets() {
    // Add Sun
    const sun = new THREE.Mesh(
      new THREE.SphereGeometry(150, 32, 16),
      new THREE.MeshPhongMaterial({ color: 0xFFFF00 })
    );
    sun.position.set(800, 400, -800);
    this.scene.add(sun);

    // Add Venus
    const venus = new THREE.Mesh(
      new THREE.SphereGeometry(70, 32, 16),
      new THREE.MeshPhongMaterial({ color: 0xFF6347 })
    );
    venus.position.set(1200, 600, -1200);
    this.scene.add(venus);

    // Add Mars
    const mars = new THREE.Mesh(
      new THREE.SphereGeometry(90, 32, 16),
      new THREE.MeshPhongMaterial({ color: 0xFF0000 })
    );
    mars.position.set(1600, 800, -1600);
    this.scene.add(mars);

    // Add Jupiter
    const jupiter = new THREE.Mesh(
      new THREE.SphereGeometry(120, 32, 16),
      new THREE.MeshPhongMaterial({ color: 0xFFA500 })
    );
    jupiter.position.set(2000, 1000, -2000);
    this.scene.add(jupiter);

    // Add Moon
    const moon = new THREE.Mesh(
      new THREE.SphereGeometry(30, 32, 16),
      new THREE.MeshPhongMaterial({ color: 0xCCCCCC })
    );
    moon.position.set(100, 200, -100);
    this.scene.add(moon);

    // Add Neptune
    const neptune = new THREE.Mesh(
      new THREE.SphereGeometry(100, 32, 16),
      new THREE.MeshPhongMaterial({ color: 0x0080FF })
    );
    neptune.position.set(2400, 1200, -2400);
    this.scene.add(neptune);

    // Add Uranus
    const uranus = new THREE.Mesh(
      new THREE.SphereGeometry(80, 32, 16),
      new THREE.MeshPhongMaterial({ color: 0x00FFFF })
    );
    uranus.position.set(2800, 1400, -2800);
    this.scene.add(uranus);
  }

  addBlobs() {
    // Add Blob Geometry
    const blobGeometry = new THREE.SphereGeometry(50, 32, 32);
    const blobMaterial = new THREE.MeshPhongMaterial({ color: 0x00FF00 });
    const blobs = new THREE.Object3D();

    for (let i = 0; i < 10; i++) {
      const blob = new THREE.Mesh(blobGeometry, blobMaterial);
      blob.position.set(
        Math.random() * 5000 - 2500,
        Math.random() * 5000 - 2500,
        Math.random() * 5000 - 2500
      );
      blobs.add(blob);
    }

    this.scene.add(blobs);
  }

  handleResize = () => {
    const width = this.mount.clientWidth;
    const height = this.mount.clientHeight;
    this.renderer.setSize(width, height);
    this.camera.aspect = width / height;
    this.camera.updateProjectionMatrix();
  };


  scrollCamera = () => {
    const scrollPosition = window.scrollY;
    if (!isNaN(scrollPosition)) {
      this.camera.position.y = -scrollPosition / 4;
    } else {
      console.error("Scroll position is NaN");
    }
  };

  componentWillUnmount() {
    this.stop();
    this.mount.removeChild(this.renderer.domElement);
    window.removeEventListener('scroll', this.scrollCamera);
    window.removeEventListener('resize', this.handleResize);
  }

  start = () => {
    if (!this.frameId) {
      this.frameId = requestAnimationFrame(this.animate);
    }
  };

  stop = () => {
    cancelAnimationFrame(this.frameId);
  };

  animate = () => {
    this.camera.rotation.x += 0.002;
    this.camera.rotation.y += 0.002;
    this.renderScene();
    this.frameId = window.requestAnimationFrame(this.animate);
  };

  renderScene = () => {
    this.renderer.render(this.scene, this.camera);
  };

  render() {
    return (
      <div
        style={{
          zIndex: -1,
          opacity: 0.5,
          position: 'fixed',
          top: 0,
          width: '100vw',
          height: '100vh',
          backgroundBlendMode: 'lighten',
        }}
        ref={(mount) => {
          this.mount = mount;
        }}
      />
    );
  }
}

export default CanvasThree;