import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import VideoHome from "../components/VideoHome";
import Grid from "../components/Grid";
import { colors } from "../styles/globals";
import Main from "../components/Main";
import LeftraruStar from "../components/LeftraruStar";
import BGColorChange from "../components/BGColorChange";
import { Reveal, Tween } from 'react-gsap';
import { NavLink } from "react-router-dom";

const LinkTo = styled(NavLink)`
  position: relative;
  text-indent: 0;
  text-decoration: none;
  display: inline-block;
  font-size: 1.5rem;
  align-items: center;
  line-height: 2rem;
  text-transform: uppercase;
  transition: 1s all;
  background: transparent;
  font-weight: 750;
  color: ${colors.white};


  &.active,
  &:hover {
	color: 000000;
  }

  &:hover::before {
	transform-origin: left;
	transform: scaleX(1);
  }

  &::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 4px;
  border-radius: 4px;
  background-color: #FFF;
  bottom: 0;
  left: 0;
  transform-origin: right;
  transform: scaleX(0);
  transition: transform .3s ease-in-out;
}
}`;

const Reel= styled.h2`

color: ${colors.white};
text-align:center;
align-center:center;
width:100vw;
selectable:false;
font-size:1.8rem;

@media (max-width: 720px) {
  font-size: 1.2rem;

}
}`;


const ActionCard = styled.div`
 
  background-position: center;
  box-sizing: border-box;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100vw;
`;


const ActionCardList = styled.div`
 
  background-position: center;
  box-sizing: border-box;
  height: 200px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  width: 100vw;
`;


const ActionButton = styled(Link)`
  background-color: #ff4081;
  color: white;
  padding: 10px 20px;
  text-decoration: none;
  font-size: 2rem;
  border-radius: 5px;
  transition: all 0.3s ease;
  &:hover {
    background-color: #c7345e;
  }
`;

const TitleHolder = styled.h2`
  color: ${colors.white};
  text-align: center;
  font-size: 2rem;
  margin-bottom: 3px;

  @media (max-width: 720px) {
    font-size: 1.5rem;
    padding: 0 15% 10% 15%;
  }
`;

const IntroTextHolder = styled.p`
  color: ${colors.white};
  text-align:center;
  font-size:1.8rem;
  margin-bottom:3px;
  padding: 0 25% 10% 25%;

  &:before {
  }

  @media (max-width: 720px) {
    font-size: 1.2rem;
    padding: 0 15% 10% 15%;
  }
`;


const EndTextHolder = styled.p`
  color: ${colors.white};
  text-align:center;
  font-size:1.8rem;
  width:100%;
  max-width:70%;
  margin:0 auto;
  &:before {
  }

  @media (max-width: 720px) {
    font-size: 1.2rem;
  }
`;

const AllWork = styled.div`
  padding: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  background: linear-gradient(
    135deg,
    ${colors.orange} 0%,
    ${colors.violet} 100%
  );
  text-align: center;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;


const Home = props => (
  <div>
    <BGColorChange/>
    <Main></Main>
    <VideoHome
      video={props.dataHome.videos}
      title={
        props.language === "es"
          ? props.dataHome.intro_text_español
          : props.dataHome.intro_text_english
      }
    />

    <LinkTo to="/services">
      <Reel>{props.language === "es" ? "Mira nuestro REEL" : "Watch our REEL"}  </Reel>
    </LinkTo>
    <ActionCard>
    <ActionButton to="/services">
          {props.language === "es" ? "REEL": "REEL"}
      </ActionButton>
    </ActionCard>
    <LeftraruStar/>
    <Reveal repeat>
      <Tween from={{ opacity: 0 }} duration={2}>
        <div>
          <TitleHolder>
              {props.language === "es" ? "Proyectos Narrativos" : "Storytelling Projects"}
          </TitleHolder>
          <IntroTextHolder>
              {props.language === "es" ? props.dataHome.intro_narrativas : props.dataHome.intro_narratives }
          </IntroTextHolder>
         </div>
      </Tween>
    </Reveal>
    <Grid data={props.data} language={props.language} gridType="STORYTELLING"/>
    <LeftraruStar/>
    <Reveal repeat>
      <Tween from={{ opacity: 0 }} duration={2}>
        <div>
         <TitleHolder>
            {props.language === "es" ? "Proyectos Interactivos" : "Interactive Projects"}
         </TitleHolder>
         <IntroTextHolder>
            {props.language === "es" ? props.dataHome.intro_interactivo : props.dataHome.intro_interactive}
          </IntroTextHolder>
         </div>
      </Tween>
    </Reveal>
    <Grid data={props.data} language={props.language} gridType="INTERACTIVE"/>
    <LeftraruStar/>
    <Reveal repeat>
      <Tween from={{ opacity: 0 }} duration={2}>
        <div>
        <TitleHolder>
          {props.language === "es" ? "Identidad" : "Branding"}
        </TitleHolder>
        <IntroTextHolder>
          {props.language === "es" ? props.dataHome.intro_identidad : props.dataHome.intro_identity }
        </IntroTextHolder>
        </div>
      </Tween>
    </Reveal>
    <Grid data={props.data} language={props.language} gridType="BRANDING"/>
    <LeftraruStar/>
    <Reveal repeat>
      <Tween from={{ opacity: 0 }} duration={2}>
        <div>
          <TitleHolder>
              {props.language === "es" ? "Proyectos Comerciales" : "Commercial Projects"}
          </TitleHolder>
          <IntroTextHolder>
              {props.language === "es" ? props.dataHome.intro_comercial : props.dataHome.intro_commercial}
          </IntroTextHolder>
       </div>
      </Tween>
    </Reveal>
    <Grid data={props.data} language={props.language} gridType="COMERCIAL"/>
    <LeftraruStar/>
    <Reveal repeat>
      <Tween from={{ opacity: 0 }} duration={2}>
        <div>
          <TitleHolder>
              {props.language === "es" ? "Juegos de Mesa" : "Board Games"}
          </TitleHolder>
          <IntroTextHolder>
              {props.language === "es" ? props.dataHome.intro_juegos : props.dataHome.intro_games}
          </IntroTextHolder>
        </div>
      </Tween>
    </Reveal>
    <Grid data={props.data} language={props.language} gridType="GAMES"/>
    <LeftraruStar/>  
    <AllWork>
   

    </AllWork>
    <EndTextHolder>
          {props.language === "es" ? "Descubre el mundo de posibilidades que ofrecemos en La Fabulosa, donde lo fabuloso cobra vida en cada proyecto creativo." : "Explore the world of possibilities we offer at La Fabulosa, where the fabulous comes to life in every creative project." }
      </EndTextHolder>
      <ActionCardList backgroundImage="">

        <ActionButton to="/about">
          {props.language === "es" ? "Reel": "Reel"}
        </ActionButton>
        <ActionButton to="/services">
          {props.language === "es" ? "Sobre nosotros": "About"}
        </ActionButton>
        <ActionButton to="/contact">
          {props.language === "es" ? "Escribenos": "Contact Us"}
        </ActionButton>
      </ActionCardList>
  </div>
);

const mapStateToProps = state => {
  return {
    data: state.data.posts,
    dataHome: state.data.pages[2].acf,
    dataContact: state.data.pages[0].acf,
    language: state.data.language
  };
};

export default connect(mapStateToProps)(Home);
