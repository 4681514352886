import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { playVideo } from "../redux/actions";
import { Link } from "react-router-dom";
import { colors } from "../styles/globals";
import { Reveal, Tween } from 'react-gsap';
import { Waypoint } from 'react-waypoint';

const Wrap = styled.div`
  top: 0px;
  grid-gap: 1rem;
  padding: 30px;
  position: relative;
  display: grid;
  align-items: stretch;
  margin: 0 auto;

  &.COMERCIAL {
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    grid-column: 1 / 1;
    max-width: 70%;
  }

  &.GAMES {
    width:100%;
    @media (min-width: 840px) {
      max-width: 33vw;
    }
  }

  &.BRANDING {
    grid-template-columns: repeat(auto-fit, minmax(60px, 30vw));
    width: 100%;
    grid-gap: 0.5rem;
    max-width: 33vw;
    grid-column: 33vw 33vw 33vw;
  }

  &.STORYTELLING {
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    grid-column: span 3; /* Display 3 columns for STORYTELLING */
    width: 70%; /* Adjust the width to use the entire available space */
  }

  &.INTERACTIVE {
   
  }

  &:hover {
    > a > div {
      opacity: 1;
      h4 {
        opacity: 1;
      }
      h3 {
        /* Add hover styles for h3 */
      }
    }
  }
`;

const TextHolder = styled.div`
  position: relative;
  transform: none;
  border-top: 4px solid white;
  margin-top: 10px;
  text-align: left;
  /* Add TextHolder styles */
`;

const ImageHolder = styled.img`
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
  width: 100%;
  /* height: 250px; */ /* Remove the fixed height */
  object-fit: cover; /* Add object-fit property */
  position: relative;
  overflow: hidden;
`;

const LinkTo = styled(Link)`
  color: ${colors.white};
  text-decoration: none;
  display: inline;
  position: relative;

  h4 {
    font-size: 1rem;
    font-weight: 500;
    text-transform: uppercase;
    font-style: italic;
    opacity: 1;
    margin: 5px 0 0 -3px;
    /* Add h4 styles */
  }

  h3 {
    font-size: 0.8rem;
    font-weight: 700;
    font-style: italic;
    text-transform: uppercase;
    color: ${colors.red};
    margin: 2px 0 0 -3px;
    line-height: 1em;
    /* Add h3 styles */
  }

  h3, h4 {
    transition: transform 0.8s, opacity 1.2s;
    transition-timing-function: cubic-bezier(0.1, 0.1, 0.2, 1), ease;
  }
`;

class Box extends Component {
  state = {
    activeElements: [],
  }

  handleLink = e => {
    console.log("handleLink", this.props.boxtype);
    // if (!this.props.link) {
    //   e.preventDefault();
    //   this.props.playVideo(this.props.videoUrl);
    // }
  };

  contentIn = e => {
    // this.state.activeElements.push(e);
    // console.log("contentIn", e, this.state.activeElements);
  }

  contentOut = e => {
    // console.log("contentOut", e);
  }

  render() {
    return (
      <Wrap style={{ visibility: this.props.visible }} className={this.props.boxtype}>
        <LinkTo to={this.props.link ? `/work/${this.props.link}` : "/"} onClick={this.handleLink}>
          <Reveal repeat>
            <Tween from={{ opacity: 0, y: 100, stagger: {
              each: 1,
              from: "center",
              grid: "auto",
              ease: "power2.inOut",
            }}} duration={0.7}>
              <div>
                <Waypoint onEnter={this.contentIn} onLeave={this.contentOut}>
                  {/* Add loading="lazy" attribute for lazy loading */}
                  <ImageHolder src={this.props.image} loading="lazy" />
                </Waypoint>
                <TextHolder>
                  <h4>{this.props.title}   {(!this.props.client) ? null : " - " } {this.props.client}</h4>
                  <h3>{this.props.category}  {(!this.props.country) ? null : " - " } {this.props.country}</h3>
                </TextHolder>
              </div>
            </Tween>
          </Reveal>
        </LinkTo>
      </Wrap>
    );
  }
}

export default connect(
  null,
  { playVideo }
)(Box);